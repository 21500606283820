import { HashLink } from 'react-router-hash-link';
import {
	PhoneIcon,
	EnvelopeIcon,
	MapIcon,
	ChevronRightIcon,
	BellIcon,
} from '@heroicons/react/24/outline';
import socials from '@/assets/data/socials';
import { Link } from 'react-router-dom';

export const Footer = () => {
	const contacts = [
		{
			icon: <PhoneIcon className='icon-xs' />,
			label: '(+250) 789-818-818',
		},
		{
			icon: <PhoneIcon className='icon-xs' />,
			label: '(+250) 785-945-008',
		},
		{
			icon: <EnvelopeIcon className='icon-xs' />,
			label: 'booking.igitegohotels@gmail.com',
		},
		{
			icon: <MapIcon className='icon-xs' />,
			label: 'Kigali, Rwanda KK 366 St',
		},
	];

	const menus = [
		{
			path: '/#rooms',
			label: 'Rooms',
		},
		{
			path: '/#about',
			label: 'About Us',
		},
		{
			path: '/#other-hotels',
			label: 'Other Hotels',
		},
		{
			path: '/contact',
			label: 'Contact',
		},
	];

	const currentYear = new Date().getUTCFullYear();

	return (
		<footer className='bg-dark-blue'>
			<div className='container grid grid-cols-1 gap-8 py-12 md:grid-cols-4'>
				<div>
					<h2 className='text-lg font-bold uppercase text-white'>
						Igitego Boutique <span className='text-danger'>Hotel</span>
					</h2>

					<p className='mt-6 text-[15px] font-light leading-6 text-light-gray'>
						One of the best hotels in Kigali city Rwanda, seeks to give the
						business or leisure traveler a relaxing home-away- from-home
						atmosphere.
					</p>
				</div>

				<div>
					<h2 className='text-sm font-bold uppercase text-white'>Contact</h2>

					<div className='mt-6 space-y-3 text-[15px] font-light text-light-gray'>
						{contacts.map((contact, key) => (
							<div key={key} className='flex items-center gap-2'>
								{contact.icon}
								<p>{contact.label}</p>
							</div>
						))}
					</div>
				</div>

				<div>
					<h2 className='text-sm font-bold uppercase text-white'>Links</h2>

					<ul className='mt-6 space-y-2'>
						{menus.map((menu, key) => (
							<li key={key}>
								<HashLink
									to={menu.path}
									className='smooth-hover flex items-center gap-1 text-[15px] font-light text-light-gray hover:font-normal hover:text-danger'
								>
									<ChevronRightIcon className='icon-xs' />
									{menu.label}
								</HashLink>
							</li>
						))}
					</ul>
				</div>

				<div>
					<h2 className='text-sm font-bold uppercase text-white'>Newsletter</h2>

					<p className='mt-6 text-[15px] font-light leading-6 text-light-gray'>
						Subscribe to our newsletter to never miss any of our new updates
					</p>

					<Link
						to='/subscribe'
						className='btn btn-success mt-3 w-full gap-1 rounded-md py-3'
					>
						<BellIcon className='icon-sm' />
						Join Newsletter
					</Link>
				</div>
			</div>

			<div className='container'>
				<div className='flex flex-col-reverse items-center justify-between gap-4 border-t border-light-gray/20 py-5 md:flex-row'>
					<div>
						<p className='text-[15px] font-medium text-light-gray'>
							Copyright &copy; {currentYear} All Rights Reserved.
						</p>
					</div>

					<div className='flex items-center gap-4'>
						{socials.map((social, key) => (
							<a key={key} href={social.link} target='_blank' rel='noreferrer'>
								<img alt={social.link} src={social.icon} />
							</a>
						))}
					</div>
				</div>
			</div>
		</footer>
	);
};
