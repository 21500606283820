import { HomeModernIcon } from '@heroicons/react/24/outline';

export const AboutUs = () => {
	const services = [
		{
		  icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
		  label: "Rooms",
		  image: "/service/room2.jpeg",
		  desc: "Experience comfort and luxury in every stay at our hotel. Our thoughtfully designed rooms offer a perfect blend of modern amenities and cozy elegance, providing a peaceful retreat for both leisure and business travelers.",
		},
		{
		  icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
		  image: "/service/wedding.jpeg",
		  label: "weddings",
		  desc: "Celebrate your special day in our enchanting wedding hall, where dreams come to life. With elegant decor and impeccable service, we create the perfect backdrop for your love story, ensuring an unforgettable beginning to your forever.",
		},
		{
		  icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
		  label: "Restaurant",
		  image: "/service/restaurant.jpeg",
		  desc: "Enjoy a culinary journey at our restaurant, where exquisite flavors and a warm ambiance come together. From mouthwatering appetizers to decadent desserts, savor a diverse menu crafted with passion and precision.",
		},
		{
		  icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
		  label: "Bar",
		  image: "/service/bar.jpeg",
		  desc: " Immerse yourself in the vibrant atmosphere of our bar services. Sip on expertly crafted cocktails and savor a selection of fine beverages. Whether it's a casual evening or a lively celebration, our bar is the ideal spot to unwind, socialize, and enjoy a memorable night out.",
		},
	  ];

	return (
		<section id='about' className=" py-14 md:w-screen">
		<div className="container">
		  <div className="flex flex-col container-about  items-center justify-center gap-12 md:flex-row">
			<div className="md:basis-1/2 py-4">
			  <p className="text-sm text-center mb-4 font-medium uppercase text-white">
				About Us
			  </p>
  
			  <h2 className="mt-2 text-3xl text-center font-semibold text-white md:text-5xl">
			  Welcome To Igitego Boutique Hotel
			  </h2>
  
			  <p className="my-6 text-xl text-center   font-raleway font-normal  leading-relaxed text-white md:my-10">
			  One of the best hotels in Kigali city Rwanda, seeks to give the
						business or leisure traveler a relaxing home-away from-home
						atmosphere.
			  </p>
  
			  <p className=" white font-medium text-white  font-serif text-2xl">
				Manager Director:{" "}
				<a
				  href="mailto:deodathe2004@gmail.com"
				  className="text-white font-extrabold hover:underline"
				>
				  Deodathe Ndazivunye
				</a>
			  </p>
			</div>
		  </div>
		  <h1 className="text-center text-3xl mt-4 font-normal text-gray-400">Our Services</h1>
		  <div className="mt-2 grid grid-cols-1 flex-wrap justify-between gap-8 md:grid-cols-2">
			{services.map((service, key) => (
			  <div key={key} className="flex-1 p-5">
				<div className="relative h-60 overflow-hidden">
				  <img
					src={service.image}
					alt={service.label}
					className="h-full w-full  object-cover"
				  />
				</div>
				<h1 className="mt-2 text-sm font-medium text-dark-blue">
				  {service.label}
				</h1>
				<p className="font-raleway font-extralight text-black">
				  {service.desc}
				</p>
			  </div>
			))}
		  </div>
		  <h1 className="text-center">For more image check in <a href="/gallary" className="text-[#1dc3b2]">gallery</a></h1>
		</div>
	  </section>
	);
};
