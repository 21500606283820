import React from "react";
import axios from "axios";
import { Form } from "@/components/elements";
import { InputField } from "@/components/elements";
import { Button } from "@/components/elements";
import toast, { Toaster } from "react-hot-toast";

import { ZodType, z } from "zod";

export type formData = {
  fullname: string;
  email: string;
  telephone: string;
  password: string;
};

const registerSchema = z.object({
  fullname: z.string().min(3, "firstname must be at least 3 characters"),
  email: z.string().min(3, "email is required").email().trim(),
  telephone: z
    .string()
    .regex(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    .trim(),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters" })
    .regex(/[A-Z]/, {
      message: "Password must contain at least one uppercase letter",
    })
    .regex(/[a-z]/, {
      message: "Password must contain at least one lowercase letter",
    })
    .regex(/[0-9]/, { message: "Password must contain at least one digit" })
    .regex(/[!@#$%^&*(),.?":{}|<>]/, {
      message: "Password must contain at least one special character",
    }),
});

export const SignupRoute = () => {
  const isLoading = false;
  const handleSignup = async (data: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/signup`, data);
      toast.success("Login successful:", response.data)
    } catch (error: any) {
      toast.error("Signup failed:", error.response.data.message)
    }
  };

  return (
    <div className="mx-auto w-1/2 pb-10 pt-10">
      <h1 
      className="uppercase text-success"
      >Signup Form</h1>
      <Form<formData, typeof registerSchema>
        schema={registerSchema}
        onSubmit={handleSignup}
        className="mt-10"
      >
        {({ register, formState }) => (
          <>
            <div className="space-y-3 text-left">
              <InputField
                type="text"
                label="Full Name"
                placeholder="Your full name"
                registration={register("fullname")}
                error={formState.errors.fullname}
                isLoading={isLoading}
                autoFocus
                className="h-14 rounded-md"
              />

              <InputField
                type="email"
                label="Email"
                placeholder="Your Email"
                registration={register("email")}
                error={formState.errors.email}
                isLoading={isLoading}
                className="h-14 rounded-md"
              />

              <InputField
                type="text"
                label="Telephone"
                placeholder="Your Phone"
                registration={register("telephone")}
                error={formState.errors.telephone}
                isLoading={isLoading}
                className="h-14 rounded-md"
              />
              <InputField
                type="text"
                label="Password"
                placeholder="Enter Password"
                registration={register("password")}
                error={formState.errors.password}
                isLoading={isLoading}
                className="h-14 rounded-md"
              />
            </div>

            <Button
              type="submit"
              variant="success"
              className="w-full mt-10 gap-2 rounded-md py-4 text-base uppercase"
            >
              Signup
            </Button>
          </>
        )}
      </Form>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};
