import React, { useState } from "react";
import { Form } from "@/components/elements";
import { useNavigate } from "react-router-dom";
import { InputField } from "@/components/elements";
import { Button } from "@/components/elements";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { ZodType, z } from "zod";
export type loginFormData = {
  email: string;
  password: string;
};

const loginSchema = z.object({
  email: z.string().min(1, "email is required").trim().email(),
  password: z.string().min(1, "password field is empty"),
});
export const LoginRoute = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/signin`,
        data
      );
      const { token } = response.data;
      localStorage.setItem("token", token);
      toast.success("Login successful:", response.data);
      if (response.data.user.role === "superAdmin") {
        navigate("/dashboard");
      } else {
        navigate("/");
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className=" mx-auto w-1/2 pb-10 pt-10">
      <h1 className="uppercase text-success">Signin Form</h1>
      <Form<loginFormData, typeof loginSchema>
        schema={loginSchema}
        onSubmit={handleLogin}
        className="mt-10"
      >
        {({ register, formState }) => (
          <>
            <div className="space-y-3 text-left">
              <InputField
                type="email"
                label="Email"
                placeholder="Your Email"
                registration={register("email")}
                error={formState.errors.email}
                isLoading={isLoading}
                className="h-14 rounded-md"
              />
              <InputField
                type="text"
                label="Password"
                placeholder="Enter Password"
                registration={register("password")}
                error={formState.errors.password}
                isLoading={isLoading}
                className="h-14 rounded-md"
              />
            </div>

            <Button
              type="submit"
              variant="success"
              className="mt-10 w-full gap-2 rounded-md py-4 text-base uppercase"
            >
              Signin
            </Button>
          </>
        )}
      </Form>
      <p className="flex items-center justify-center gap-2 p-2 text-sm">
        Don't have an account{" "}
        <a href="/signup" className="text-success">
          Signup
        </a>
      </p>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};
