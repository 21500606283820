import * as z from 'zod';
import { Link } from 'react-router-dom';
import {
	PhoneIcon,
	MapPinIcon,
	ClockIcon,
	EnvelopeIcon,
} from '@heroicons/react/24/outline';
import { Button, Form, InputField } from '@/components/elements';
import { TextField } from '@/components/elements/input/TextField';
import { ContactPayload } from '@/app/contact/types';

const schema = z.object({
	name: z.string().min(1, 'Name required'),
	email: z.string().min(1, 'Email required').email(),
	message: z.string().min(1, 'Message required'),
});

export const ContactRoute = () => {
	const isLoading = false;

	const properties = [
		{
			icon: <PhoneIcon className='icon-md mx-auto text-success' />,
			title: 'Phone',
			desc: '+250 785-601-448',
		},
		{
			icon: <MapPinIcon className='icon-md mx-auto text-success' />,
			title: 'Address',
			desc: 'KK 366 St, Kigali',
		},
		{
			icon: <ClockIcon className='icon-md mx-auto text-success' />,
			title: 'Open Time',
			desc: '06:00 AM to 23:00 PM',
		},
		{
			icon: <EnvelopeIcon className='icon-md mx-auto text-success' />,
			title: 'Email',
			desc: 'booking.igitegohotels@gmail.com',
		},
	];

	return (
		<section className='bg-slate-100'>
			<div className='contact-banner flex h-[60vh] w-full'>
				<div className='container mt-36 text-center md:mt-28'>
					<h2 className='text-[44px] font-semibold text-white'>Contact Us</h2>

					<div className='mt-1 flex justify-center gap-3 text-base font-normal text-white'>
						<Link to='/' className='smooth-hover hover:text-success'>
							Home
						</Link>

						<p>{`>`}</p>
						<p>Contact Us</p>
					</div>
				</div>
			</div>

			<div className='mt-[-150px] px-3 md:px-20'>
				<div className='card-shadow rounded-md bg-white px-4 py-6 md:p-10'>
					<div className='grid grid-cols-1 gap-6 md:grid-cols-4'>
						{properties.map((property, key) => (
							<div key={key} className='text-center'>
								{property.icon}

								<p className='mt-4 text-xl font-medium leading-7 text-dark-blue'>
									{property.title}
								</p>

								<p className='text-base font-normal leading-7 text-slate-500'>
									{property.desc}
								</p>
							</div>
						))}
					</div>

					<div className='mt-10'>
						<iframe
							title='google-map'
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15949.770213028447!2d30.103790726726082!3d-1.9773545769349565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca79f85b29a71%3A0xbcc20f9a097f0416!2sIgitego%20Boutique%20Hotel!5e0!3m2!1sen!2srw!4v1679324270762!5m2!1sen!2srw"
							allowFullScreen
							loading='lazy'
							referrerPolicy='no-referrer-when-downgrade'
							className='h-[400px] w-full md:h-[500px]'
						/>
					</div>
				</div>
			</div>

			<div className='container py-14'>
				<div className='text-center'>
					<p className='font-medium uppercase text-success'>Contact Us</p>

					<h2 className='mt-2 text-4xl font-semibold text-dark-blue'>
						Leave Message
					</h2>
				</div>

				<Form<ContactPayload, typeof schema>
					schema={schema}
					className='mt-14'
					onSubmit={payload => console.log('payload', payload)}
				>
					{({ register, formState }) => (
						<>
							<div className='space-y-7'>
								<div className='flex flex-col justify-between gap-7 md:flex-row'>
									<InputField
										type='text'
										placeholder='Your Name'
										registration={register('name')}
										error={formState.errors.name}
										isLoading={isLoading}
										className='h-14'
									/>

									<InputField
										type='email'
										placeholder='Your Email'
										registration={register('email')}
										error={formState.errors.email}
										isLoading={isLoading}
										className='h-14'
									/>
								</div>

								<TextField
									placeholder='Your Message'
									registration={register('message')}
									error={formState.errors.message}
									isLoading={isLoading}
									className='h-40'
								/>
							</div>

							<div className='mt-10 flex justify-center'>
								<Button
									type='submit'
									variant='success'
									size='lg'
									isLoading={isLoading}
									className='px-12'
								>
									Send Message
								</Button>
							</div>
						</>
					)}
				</Form>
			</div>
		</section>
	);
};
