import Banner1 from '@/assets/images/building.jpg';
import Banner2 from '@/assets/images/boutique.jpg';
import Banner3 from '@/assets/images/apart.jpg';

// import Banner4 from '@/assets/images/hooly-guest-in.jpg';

const hotels = [
	{
		name: 'Igitego Hotel',
		link: 'https://igitegohotel.com',
		cover: Banner1,
		desc: 'One of the best hotels in Kigali city Rwanda, seeks to give the business or leisure traveler a relaxing home-away from-home atmosphere.',
	},
	{
		name: 'Igitego ApartHotel',
		link: 'https://apart.igitegohotel.com',
		cover: Banner3,
		desc: 'IGITEGO ApartHotel is Kigali’s 1st entirely eco-friendly apartment that includes domestically crafted property teak wood furniture, the whole building and around the building is naturally green, best wooden doors and best bathrooms.',
	},
	{
		name: 'Igitego Boutique Hotel',
		link: 'https://boutique.igitegohotel.com',
		cover: "/service/boutique.jpeg",
		desc: 'Situated in Kigali near Amahoro Stadium and KIGALI ARENA, Igitego Hotel features accommodation with a restaurant, free private parking, and a bar. All rooms boast a flat-screen TV with cable channels and a private bathroom.',
	},
	// {
	// 	name: 'Holly Guest Inn',
	// 	link: 'https://hollyguestinn.com',
	// 	cover: Banner4,
	// 	desc: 'Holly Guest Inn is a guest house located in Kigali city, Kicukiro-Sonatube. Guest rooms are simple, yet comfortable. It is a tranquil place to stay with a friendly atmosphere and a focus on high quality customer service.',
	// },
];

export default hotels;
