import React, { useState, useCallback, useEffect, useMemo } from "react";
import axios from "axios";
import { Form } from "@/components/elements";
import { InputField, SelectField } from "@/components/elements";
import { Button } from "@/components/elements";
import { MdCreateNewFolder } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { useForm, SubmitHandler } from "react-hook-form";
import { any, z } from "zod";
import { AiFillCloseCircle } from "react-icons/ai";
import RoomTable from "../components/RoomTable";
import { CiCircleRemove } from "react-icons/ci";


export type formData = {
  title: string;
  description: string;
  amenities: string[];
  time: string;
  price: string;
  perRoomImages: FileList | null;
};

const roomSchema = z.object({
  title: z.string().min(3, "Title must be at least 3 characters"),
  description: z.string().min(5, "Description must be at least 5 characters"),
  price: z.string().min(1, "price required"),
  amenities: z.array(z.string()).default([]),
  perRoomImages: z.any(),
});

export const Content = () => {
  const token = localStorage.getItem("token");

  const amenities = [
    { value: "wifi", label: "Wifi" },
    { value: "parking", label: "Parking" },
    { value: "pool", label: "Pool" },
    { value: "gym", label: "Gym" },
    { value: "spa", label: "Spa" },
    { value: "laundry", label: "Laundry" },
    { value: "room service", label: "Room Service" },
    { value: "air conditioning", label: "Air Conditioning" },
    { value: "tv", label: "TV" },
    { value: "kitchen", label: "Kitchen" },
    { value: "smoking", label: "Smoking" },
    { value: "pets", label: "Pets" },
    { value: "breakfast", label: "Breakfast" },
    { value: "lunch", label: "Lunch" },
    { value: "dinner", label: "Dinner" },
    { value: "beach", label: "Beach" },
  ];

  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]); 

  

  const onDrop = useCallback((acceptedFiles: any) => {
    //@ts-ignore
    setSelectedImages((prevState: any) => [...prevState, ...acceptedFiles]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, maxFiles: 4 });

  const styles = useMemo(
    () => ({
      ...(isDragAccept ? { borderColor: "#00e676" } : {}),
      ...(isDragReject ? { borderColor: "#ff1744" } : {}),
    }),
    [isDragAccept, isDragReject]
  );

  const handleRemoveImage = (index: any) => {
    setSelectedImages((prevState) => prevState.filter((_, i) => i !== index));
  };

  const submitdata = {
    title: "",
    description: "",
    price: "",
    time: "",
    amenities: [],
    perRoomImages: [],
  };

  const [data, setData] = useState(submitdata);


  const [rooms, setRooms] = useState([]);
  const [isCreateModel, setIsCreateModel] = useState(false);

  const handleShowCreateModel = (e: any) => {
    e.preventDefault();
    setIsCreateModel(true);
  };

  const handleCloseCreateModel = (e: any) => {
    e.preventDefault();
    setIsCreateModel(false);
  };

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };


  const handleRoomCreation = async (data: any) => {
    const formData = new FormData();
  
    selectedImages.forEach((image, index) => {
      formData.append(`perRoomImages`, image);
    });
  
    formData.append("title", data.title);
    formData.append("price", data.price);
    formData.append("description", data.description);
    selectedAmenities.forEach((amenity) => {
      formData.append("amenities", amenity);
    });
    const response = await toast.promise(
      axios.post(`${process.env.REACT_APP_BASE_API_URL}/rooms`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
      {
        loading: 'create room...',
        success: <b>Room created successfully!</b>,
        error: <b>Failed to create room.</b>,
      }
    );
    setIsCreateModel(false)
    return response
  };


  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/allRooms`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRooms(response.data.rooms);
        
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    };

    fetchRooms();
  }, [rooms]);

  return (
    <>
      <div className="mx-auto w-fit pb-10 pt-10">
        <Button
          type="submit"
          variant="success"
          onClick={handleShowCreateModel}
          className="mt-2 w-full gap-2 rounded-md py-2 text-base uppercase"
        >
          <MdCreateNewFolder />
          Create Room
        </Button>
        {isCreateModel && (
          <div className="fixed left-0 top-0 z-50 flex w-full items-center justify-center bg-black bg-opacity-50 md:h-screen">
            <div className="w-1/2 rounded-md bg-white p-8">
              <h1 className="flex justify-between uppercase text-success">
                Room Creation Form{" "}
                <AiFillCloseCircle
                  onClick={handleCloseCreateModel}
                  className="cursor-pointer text-3xl"
                />
              </h1>
              <Form<formData, typeof roomSchema>
                schema={roomSchema}
                onSubmit={handleRoomCreation}
                className="mt-10"
              >
                {({ register, formState }) => (
                  <>
                    <div className="space-y-3 text-left">
                      <InputField
                        type="text"
                        label="Title"
                        onChange={handleChange}
                        placeholder="Room title"
                        registration={register("title")}
                        error={formState.errors.title}
                        autoFocus
                        className="h-14 rounded-md"
                      />

                      <InputField
                        type="text"
                        label="Description"
                        onChange={handleChange}
                        placeholder="Room description"
                        registration={register("description")}
                        error={formState.errors.description}
                        className="h-14 rounded-md"
                      />

                      <InputField
                        type="text"
                        label="Price"
                 
                        placeholder="Room Price"
                        onChange={handleChange}
                        registration={register("price")}
                        error={formState.errors.description}
                        className="h-14 rounded-md"
                      />

                      <InputField
                        type="text"
                        label="Time"
                        placeholder="Day or Month"
                        registration={register("time")}
                        error={formState.errors.time}
                        onChange={handleChange}
                        className="h-14 rounded-md"
                      />
<Select
  options={amenities}
  onChange={(selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedAmenities(selectedValues);
  }}
  isMulti={true}
  value={selectedAmenities.map((value) => ({ value, label: value }))}
/>


<div className="xs:static xs:mt-[10px] xs:p-2 laptop:static mt-[-10] inline-grid grid-cols-3 grid-rows-1 gap-[5px]">
        {selectedImages.length < 4 && (
          <div
            className="... xs:w-[150px] xs:h-[150px] h-[100px] w-[100px] border  border-gray-200 bg-white text-5xl text-slate-400 "
            {...getRootProps({ styles })}
          >
            <input data-testid="dropzone" {...getInputProps()} />
            {isDragActive ? (
              <p className="xs:mt-[45px] xs:ml-[25px] ml-[55px] mt-[70px] text-2xl">
                Drop it here...
              </p>
            ) : (
              <p className="xs:mt-[45px] xs:ml-[55px] ml-[35px] mt-[25px]">+</p>
            )}
          </div>
        )}
        {selectedImages.length > 0 &&
          selectedImages.map((image, index) => (
            <div
              className="... xs:w-[150px] xs:h-[180px] xs:p-[15px] col-span-1 h-[100px] w-[100px]  border border-gray-200 bg-white p-[5px] text-5xl text-slate-400 relative"
              key={index}
            >
              <img
                src={`${URL.createObjectURL(image)}`}
                alt=""
                className="h-full w-[100%] object-cover hover:opacity-[0.6]"
              />
              <button
                onClick={() => handleRemoveImage(index)}
                className="absolute top-0 right-0 p-2 cursor-pointer bg-red-500 text-white rounded-full hover:bg-red-600"
              >
                <CiCircleRemove  />
              </button>
            </div>
          ))}
      </div>
                    </div>

                    <Button
                      type="submit"
                      variant="success"
                      className="mt-10 w-1/2 gap-2 rounded-md py-4 text-base uppercase"
                    >
                      Create Room
                      <MdCreateNewFolder />
                    </Button>
                  </>
                )}
              </Form>
            </div>
          </div>
        )}

        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div>{rooms.length > 0 && <RoomTable rooms={rooms} />}</div>
    </>
  );
};
