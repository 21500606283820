import { RoomCard } from "@/app/home/components/RoomCard";
import { RoomType } from "@/app/room/types";
import SkeletonLoading from "@/components/containers/SkeletonLoading";
import { SpinnerDotted } from "spinners-react/lib/esm/SpinnerDotted";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const OurRooms = () => {
  const token = localStorage.getItem("token");
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRooms = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/allRooms` );
        setRooms(response.data.rooms);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    };
    fetchRooms();
  }, []);

  return (
    <section id="rooms" className="py-14 hidden">
      <div className="container">
        <div className="grid-col grid h-full gap-4 md:grid-cols-4">
          {isLoading ? (
            <div className="flex md:w-full md:items-center md:justify-center">
              <SpinnerDotted />
            </div>
          ) : (
            <>
              {rooms?.length > 0 ? (
                <>
                  {rooms?.map((room: RoomType, index: number) => (
                    <Link to={`/room/${room._id}`}>
                      <div className="">
                        <div className="overflow-hidden rounded-sm md:w-full">
                          <img
                            alt={room.title}
                            src={room.perRoomImages[0]}
                            className="h-56 w-full rounded-sm object-cover md:h-56"
                          />
                        </div>

                        <div className="md:flex-1">
                          <div className="flex items-center justify-between md:block">
                            <h2
                              className={`text-normal font-light  text-dark-blue`}
                            >
                              {room.title}
                            </h2>
                          </div>

                          <div className="my-1 grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm font-medium text-slate-400">
                                Services:
                              </p>

                              <p className="text-[15px] font-medium text-dark-blue">
                                {room.amenities
                                  .slice(0, 2)
                                  .map((fac: string, index: number) => {
                                    return index === room.amenities.length - 1
                                      ? fac
                                      : fac + ", ";
                                  })}
                                ...
                              </p>
                            </div>
                          </div>

                          <Link
                            to={`/room/${room._id}`}
                            className="flex items-center justify-center rounded-sm bg-[#b5651d] px-2 py-[0.8rem] text-base  font-light text-white "
                          >
                            Book now ${room.price} / {room.perUnitTime}
                          </Link>
                        </div>
                      </div>
                    </Link>
                  ))}
                </>
              ) : (
                <div className="text-center">
                  <h3 className="text-md font-semibold text-red-500">
                    No Rooms Found
                  </h3>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
